var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-simple-table", {
        staticStyle: { border: "0.5px solid #42A5F5", "border-radius": "10px" },
        attrs: { loading: true, "fixed-header": "", height: "100%" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "caption",
                  [
                    _vm._v(" " + _vm._s(_vm.data.title) + " "),
                    _vm.loading
                      ? _c("v-progress-linear", {
                          attrs: { indeterminate: "", color: "blue" }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c("thead", [
                  _c(
                    "tr",
                    [
                      _c("th", {
                        staticClass: "pa-0 ma-0 text-center",
                        staticStyle: { width: "10px" }
                      }),
                      _vm._l(_vm.days, function(d, index) {
                        return _c(
                          "th",
                          {
                            key: index,
                            staticClass: "text-center",
                            attrs: { colspan: "4" }
                          },
                          [_vm._v(_vm._s(d.name))]
                        )
                      })
                    ],
                    2
                  ),
                  _c(
                    "tr",
                    [
                      _c(
                        "th",
                        { staticClass: "pa-0 ma-0 text-center" },
                        [
                          _vm.data.title && _vm.filter
                            ? _c("emc-button-icon", {
                                staticClass: "mr-2",
                                attrs: {
                                  loading: _vm.loading,
                                  icon: "mdi-refresh",
                                  text: "Atualizar",
                                  color: "light-green"
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.loadData()
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._l(_vm.data.numColumns, function(n) {
                        return _c(
                          "th",
                          {
                            key: n,
                            staticClass: "pa-0 ma-0 text-center",
                            style:
                              (n - 1) % 4 == 3 && n < _vm.data.numColumns
                                ? "border-right: 0.5px solid #42A5F5;"
                                : ""
                          },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          (n - 1) % 4 == 0
                                            ? _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                        "x-small": ""
                                                      }
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.icon.inPerson)
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [_c("span", [_vm._v(" Presencial ")])]
                            ),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          (n - 1) % 4 == 1
                                            ? _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: { "x-small": "" }
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.icon.digital)
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [_c("span", [_vm._v(" Digital ")])]
                            ),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          (n - 1) % 4 == 2
                                            ? _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                        "x-small": ""
                                                      }
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.icon.hybrid)
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [_c("span", [_vm._v(" Híbrido ")])]
                            ),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          (n - 1) % 4 == 3
                                            ? _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        color: "black",
                                                        "x-small": ""
                                                      }
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v(_vm._s(_vm.icon.total))]
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [_c("span", [_vm._v(" Total ")])]
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.data.data.values, function(shift, indexShift) {
                    return _c(
                      "tr",
                      { key: indexShift },
                      [
                        _c("td", { staticClass: "pa-0 ma-0 text-left" }, [
                          _c("small", { staticClass: "ml-1" }, [
                            _vm._v(_vm._s(_vm.shifts[indexShift].name))
                          ])
                        ]),
                        _vm._l(shift, function(day, indexDay) {
                          return _vm._l(day, function(total, indexCategory) {
                            return _c(
                              "td",
                              {
                                key:
                                  indexShift +
                                  "-" +
                                  indexDay +
                                  "-" +
                                  indexCategory,
                                staticClass: "pa-0 ma-0 text-center",
                                style:
                                  indexCategory == 3 && indexDay < 5
                                    ? "border-right: 0.5px solid #42A5F5;"
                                    : ""
                              },
                              [
                                indexCategory == 0 || indexCategory == 2
                                  ? _c("span", { staticClass: "blue--text" }, [
                                      _c("small", [_vm._v(_vm._s(total))])
                                    ])
                                  : indexCategory == 1
                                  ? _c("span", [
                                      _c("small", [_vm._v(_vm._s(total))])
                                    ])
                                  : indexCategory == 3
                                  ? _c("strong", [
                                      _c("small", [_vm._v(_vm._s(total))])
                                    ])
                                  : _vm._e()
                              ]
                            )
                          })
                        })
                      ],
                      2
                    )
                  }),
                  0
                ),
                _c("tfoot", { attrs: { "mt-2": "" } }, [
                  _c(
                    "tr",
                    [
                      _c("td", { staticClass: "pa-0 ma-0 text-left" }, [
                        _c("strong", [
                          _c("small", { staticClass: "ml-1" }, [
                            _vm._v("Totais")
                          ])
                        ])
                      ]),
                      _vm._l(_vm.data.data.totals, function(item, indexDay) {
                        return _vm._l(item, function(total, indexCol) {
                          return _c(
                            "td",
                            {
                              key: indexDay + "-" + indexCol,
                              staticClass: "pa-0 ma-0 text-center",
                              style:
                                indexDay < 5 && indexCol == 3
                                  ? "border-right: 0.5px solid #42A5F5;"
                                  : ""
                            },
                            [
                              indexCol == 0 || indexCol == 2
                                ? _c("strong", { staticClass: "blue--text" }, [
                                    _c("small", [_vm._v(_vm._s(total))])
                                  ])
                                : indexCol == 1
                                ? _c("strong", [
                                    _c("small", [_vm._v(_vm._s(total))])
                                  ])
                                : indexCol == 3
                                ? _c("strong", [
                                    _c("small", [_vm._v(_vm._s(total))])
                                  ])
                                : _vm._e()
                            ]
                          )
                        })
                      })
                    ],
                    2
                  ),
                  _c(
                    "tr",
                    [
                      _c("td", { staticClass: "pa-0 ma-0 text-center" }),
                      _vm._l(_vm.data.data.percents, function(perc, index) {
                        return _c(
                          "td",
                          {
                            key: index,
                            staticClass: "pa-0 ma-0 text-center",
                            style:
                              index < 5
                                ? "border-right: 0.5px solid #42A5F5;"
                                : "",
                            attrs: { colspan: "4" }
                          },
                          [
                            _c(
                              "v-chip",
                              {
                                staticClass: "justify-center ma-2",
                                staticStyle: { width: "50px" },
                                attrs: { color: "blue lighten-1", dark: "" }
                              },
                              [
                                _c("strong", [
                                  _c("small", [_vm._v(_vm._s(perc))])
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                ])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
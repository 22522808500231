import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=4b32d203&"
import script from "../js/index.js?vue&type=script&lang=js&"
export * from "../js/index.js?vue&type=script&lang=js&"
import style0 from "../css//index.css?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBadge,VCol,VContainer,VDivider,VRadio,VRadioGroup,VRow,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/SysClass-Web-App/SysClass-Web-App/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4b32d203')) {
      api.createRecord('4b32d203', component.options)
    } else {
      api.reload('4b32d203', component.options)
    }
    module.hot.accept("./Index.vue?vue&type=template&id=4b32d203&", function () {
      api.rerender('4b32d203', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/grid/offer_analitics/pages/Index.vue"
export default component.exports
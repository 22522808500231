var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.renderComponent
        ? _c("emc-chart-apex-base", {
            attrs: { loading: _vm.loading, data: _vm.data },
            on: {
              refresh: function($event) {
                return _vm.loadData()
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { fluid: "", "ma-0": "", "pa-0": "" } },
    [
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.data.data,
          "fixed-header": "",
          height: "500",
          loading: _vm.loading
        },
        scopedSlots: _vm._u(
          [
            {
              key: "header.campus_name",
              fn: function(ref) {
                var header = ref.header
                return [
                  _c("small", [
                    _vm._v("Linhas: " + _vm._s(_vm.data.data.length))
                  ]),
                  _c("br"),
                  _vm._v(" " + _vm._s(header.text) + " ")
                ]
              }
            },
            {
              key: "header.course_name",
              fn: function(ref) {
                var header = ref.header
                return [
                  _c("small", [
                    _vm._v("UC's: " + _vm._s(_vm.data.columns.length))
                  ]),
                  _c("br"),
                  _vm._v(" " + _vm._s(header.text) + " ")
                ]
              }
            },
            _vm._l(_vm.data.columns, function(col, index) {
              return {
                key: "header." + col.value,
                fn: function(ref) {
                  var header = ref.header
                  return [
                    _c(
                      "span",
                      { key: index },
                      [
                        _c("strong", { staticClass: "orange--text" }, [
                          _vm._v(_vm._s(_vm.totalStudents(header.value, true)))
                        ]),
                        _vm.expanded ? _c("span", [_vm._v("|")]) : _c("br"),
                        _c("strong", { staticClass: "green--text" }, [
                          _vm._v(_vm._s(_vm.totalStudents(header.value, false)))
                        ]),
                        _vm.expanded ? _c("span", [_vm._v("|")]) : _vm._e(),
                        _vm.expanded
                          ? _c(
                              "span",
                              [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      "x-small": "",
                                      color: "black",
                                      dark: ""
                                    }
                                  },
                                  [_vm._v("mdi-calculator")]
                                ),
                                _vm._v(": "),
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.somas[header.value]))
                                ]),
                                _c("br")
                              ],
                              1
                            )
                          : _c("strong", [
                              _c("br"),
                              _vm._v(
                                _vm._s(
                                  _vm.somas[header.value]
                                    ? _vm.somas[header.value]
                                    : 0
                                )
                              ),
                              _c("br")
                            ]),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "small",
                                        _vm._g(
                                          _vm._b({}, "small", attrs, false),
                                          on
                                        ),
                                        [
                                          _vm.expanded
                                            ? _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      header.text.length > 23
                                                        ? header.text.substring(
                                                            0,
                                                            23
                                                          ) + "..."
                                                        : header.text
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      header.text.substring(
                                                        0,
                                                        4
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ])
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c("span", [
                              _vm._v(" " + _vm._s(header.text) + " ")
                            ])
                          ]
                        ),
                        _c("br"),
                        _vm.renderComponent
                          ? _c(
                              "v-icon",
                              {
                                attrs: { color: _vm.color.cursar, small: "" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.selectAllItem(header.value, true)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.selectedAll[header.value] &&
                                        _vm.selectedAll[header.value].cursar
                                        ? "mdi-checkbox-marked-circle"
                                        : "mdi-circle-outline"
                                    ) +
                                    " "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm.renderComponent
                          ? _c(
                              "v-icon",
                              {
                                attrs: { color: _vm.color.ofertar, small: "" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.selectAllItem(
                                      header.value,
                                      false
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.selectedAll[header.value] &&
                                        _vm.selectedAll[header.value].ofertar
                                        ? "mdi-checkbox-marked-circle"
                                        : "mdi-circle-outline"
                                    ) +
                                    " "
                                )
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              disabled:
                                !_vm.totalSelectedByCol[header.value] ||
                                _vm.totalSelectedByCol[header.value] <= 1,
                              icon: "",
                              "x-small": ""
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.editMultiItem(header.value)
                              }
                            }
                          },
                          [
                            _c("v-icon", { attrs: { color: "primary" } }, [
                              _vm._v("mdi-pencil-outline")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            }),
            {
              key: "item.notifications",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-row",
                    [
                      _c(
                        "div",
                        { staticClass: "ma-0 pa-0" },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  "x-small": "",
                                                  icon: "",
                                                  href:
                                                    "/offer_grids?code=" +
                                                    item.base_group_code,
                                                  target: "_blank"
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  "x-small": "",
                                                  color: "green"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "mdi-arrow-right-bold-circle-outline"
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [_c("span", [_vm._v("Ir para catálogo")])]
                          )
                        ],
                        1
                      ),
                      item.notifications.inconsistency &&
                      _vm.filter && _vm.filter.notifications
                        ? _c("s-icon", {
                            attrs: {
                              "tool-tip": "Inconsistências",
                              color:
                                _vm.filter &&
                                _vm.filter.table &&
                                _vm.filter.table.inconsistency
                                  ? "red"
                                  : "gray",
                              "x-small": "",
                              icon: "mdi-alert-outline",
                              loading:
                                _vm.loadingInconsitencies &&
                                _vm.selectedItem.id == item.id
                            },
                            on: {
                              click: function($event) {
                                return _vm.loadInconsistencies(item)
                              }
                            }
                          })
                        : _vm._e(),
                      item.notifications.merge
                        ? _c("s-icon", {
                            attrs: {
                              "tool-tip": "Merge",
                              color:
                                _vm.filter &&
                                _vm.filter.table &&
                                _vm.filter.table.merge
                                  ? "light-blue"
                                  : "gray",
                              "x-small": "",
                              icon: "mdi-flag-variant"
                            }
                          })
                        : _vm._e(),
                      item.notifications.update
                        ? _c("s-icon", {
                            attrs: {
                              "tool-tip": "Atualizações",
                              color:
                                _vm.filter &&
                                _vm.filter.table &&
                                _vm.filter.table.update
                                  ? "light-blue"
                                  : "gray",
                              "x-small": "",
                              icon: "mdi-information-outline"
                            }
                          })
                        : _vm._e(),
                      item.notifications.classroom
                        ? _c("s-icon", {
                            attrs: {
                              "tool-tip": "Presencial",
                              color:
                                _vm.filter &&
                                _vm.filter.table &&
                                _vm.filter.table.classroom
                                  ? "light-blue"
                                  : "gray",
                              "x-small": "",
                              icon: "mdi-office-building"
                            }
                          })
                        : _vm._e(),
                      item.notifications.digital
                        ? _c("s-icon", {
                            attrs: {
                              "tool-tip": "Digital",
                              color:
                                _vm.filter &&
                                _vm.filter.table &&
                                _vm.filter.table.digital
                                  ? "light-blue"
                                  : "gray",
                              "x-small": "",
                              icon: "mdi-desktop-classic"
                            }
                          })
                        : _vm._e(),
                      item.notifications.hybrid
                        ? _c("s-icon", {
                            attrs: {
                              "tool-tip": "Híbrido",
                              color:
                                _vm.filter &&
                                _vm.filter.table &&
                                _vm.filter.table.hybrid
                                  ? "light-blue"
                                  : "gray",
                              "x-small": "",
                              icon: "mdi-gesture-double-tap"
                            }
                          })
                        : _vm._e(),
                      item.notifications.freshman
                        ? _c("s-icon", {
                            attrs: {
                              "tool-tip": "Calouro",
                              color:
                                _vm.filter &&
                                _vm.filter.table &&
                                _vm.filter.table.freshman
                                  ? "light-blue"
                                  : "gray",
                              "x-small": "",
                              icon: "mdi-star-box-multiple-outline"
                            }
                          })
                        : _vm._e(),
                      item.notifications.integralized
                        ? _c("s-icon", {
                            attrs: {
                              "tool-tip": "Formando",
                              color:
                                _vm.filter &&
                                _vm.filter.table &&
                                _vm.filter.table.integralized
                                  ? "light-blue"
                                  : "gray",
                              "x-small": "",
                              icon: "mdi-school-outline"
                            }
                          })
                        : _vm._e(),
                      item.notifications.algorithm
                        ? _c("s-icon", {
                            attrs: {
                              "tool-tip": "Algoritmo",
                              color:
                                _vm.filter &&
                                _vm.filter.table &&
                                _vm.filter.table.algorithm
                                  ? "light-blue"
                                  : "gray",
                              "x-small": "",
                              icon: "mdi-codepen"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "item.campus_name",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm.expanded
                    ? _c("span", [
                        _vm._v(" " + _vm._s(item.campus_name) + " "),
                        _c("br"),
                        _c("small", [_vm._v(_vm._s(item.brand_name))])
                      ])
                    : _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "span",
                                      _vm._g(
                                        _vm._b({}, "span", attrs, false),
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatTextCell(item.campus_name)
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c("span", [
                            _vm._v(" " + _vm._s(item.campus_name) + " "),
                            _c("br"),
                            _vm._v(" " + _vm._s(item.brand_name) + " ")
                          ])
                        ]
                      )
                ]
              }
            },
            {
              key: "item.course_name",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm.expanded
                    ? _c("span", [
                        _vm._v(" " + _vm._s(item.course_name) + " "),
                        _c("br"),
                        _c("small", [_vm._v(_vm._s(item.area_name))]),
                        item.category_name
                          ? _c("small", [
                              _c("br"),
                              _vm._v(_vm._s(item.category_name))
                            ])
                          : _vm._e()
                      ])
                    : _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "span",
                                      _vm._g(
                                        _vm._b({}, "span", attrs, false),
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatTextCell(item.course_name)
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c("span", [
                            _vm._v(" " + _vm._s(item.course_name) + " "),
                            _c("br"),
                            _vm._v(" " + _vm._s(item.area_name) + " "),
                            item.category_name
                              ? _c("span", [
                                  _c("br"),
                                  _vm._v(_vm._s(item.category_name))
                                ])
                              : _vm._e()
                          ])
                        ]
                      )
                ]
              }
            },
            {
              key: "item.base_group_name",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "td",
                    { staticClass: "column-fixed z-index-cell" },
                    [
                      _vm.expanded
                        ? _c("span", [
                            _vm._v(" " + _vm._s(item.base_group_name) + " "),
                            _c("br"),
                            _c("small", [_vm._v(_vm._s(item.base_group_code))]),
                            _c("br"),
                            _c("small", [_vm._v(_vm._s(item.shift_name))])
                          ])
                        : _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "span",
                                          _vm._g(
                                            _vm._b({}, "span", attrs, false),
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatTextCell(
                                                  item.base_group_name
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  " " + _vm._s(item.base_group_name) + " "
                                ),
                                _c("br"),
                                _vm._v(
                                  " " + _vm._s(item.base_group_code) + " "
                                ),
                                _c("br"),
                                _vm._v(" " + _vm._s(item.shift_name) + " ")
                              ])
                            ]
                          )
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "item.total_completed_periods",
              fn: function(ref) {
                var item = ref.item
                return [_vm._v(" " + _vm._s(item.periods) + " ")]
              }
            },
            {
              key: "item.hybridity_percent",
              fn: function(ref) {
                var item = ref.item
                return [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("percent")(item.hybridity_percent)) +
                      " "
                  )
                ]
              }
            },
            _vm._l(_vm.data.columns, function(col, index) {
              return {
                key: "item." + col.value,
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("span", { key: index }, [
                      item[col.value]
                        ? _c(
                            "span",
                            [
                              _vm.expanded
                                ? _c(
                                    "span",
                                    [
                                      _vm.renderComponent &&
                                      (!item[col.value].offer_item_status_id ||
                                        item[col.value].offer_item_status_id ==
                                          3)
                                        ? _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color: _vm.getColor(
                                                  item[col.value]
                                                ),
                                                small: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.selectItem(
                                                    col.value,
                                                    item[col.value].id
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.selected.includes(
                                                      item[col.value].id
                                                    )
                                                      ? "mdi-checkbox-marked-circle"
                                                      : "mdi-circle-outline"
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "primary"
                                              }
                                            },
                                            [_vm._v("mdi-lock-check")]
                                          ),
                                      _c(
                                        "span",
                                        {
                                          class:
                                            _vm.getColor(item[col.value]) +
                                            "--text"
                                        },
                                        [
                                          _c(
                                            "strong",
                                            { staticClass: "ml-1" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  item[col.value].status_name
                                                )
                                              )
                                            ]
                                          ),
                                          !item[col.value]
                                            .offer_item_status_id ||
                                          item[col.value]
                                            .offer_item_status_id == 3
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass: "ml-1",
                                                  attrs: {
                                                    disabled:
                                                      _vm.totalSelectedByCol[
                                                        col.value
                                                      ] > 1,
                                                    icon: "",
                                                    "x-small": ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.preventDefault()
                                                      $event.stopPropagation()
                                                      return _vm.editItem(
                                                        item[col.value],
                                                        null
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "primary"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "mdi-pencil-outline"
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c("small", [
                                            _c("br"),
                                            _vm._v(
                                              "(" +
                                                _vm._s(
                                                  item[col.value].students
                                                ) +
                                                ") "
                                            )
                                          ]),
                                          item[col.value].join_category_name
                                            ? _c("small", [
                                                _vm._v(
                                                  _vm._s(
                                                    item[col.value]
                                                      .join_category_name
                                                  )
                                                )
                                              ])
                                            : _vm._e(),
                                          item[col.value].modality_name
                                            ? _c("small", [
                                                _c("br"),
                                                _vm._v(
                                                  _vm._s(
                                                    item[col.value]
                                                      .modality_name
                                                  )
                                                )
                                              ])
                                            : _vm._e(),
                                          item[col.value].day_name
                                            ? _c("small", [
                                                _c("br"),
                                                _vm._v(
                                                  _vm._s(
                                                    item[col.value].day_name
                                                  )
                                                )
                                              ])
                                            : _vm._e(),
                                          item[col.value].work_time_offer
                                            ? _c("small", [
                                                _c("br"),
                                                _vm._v(
                                                  _vm._s(
                                                    item[col.value]
                                                      .work_time_offer
                                                  )
                                                )
                                              ])
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "span",
                                                  _vm._g(
                                                    _vm._b(
                                                      {},
                                                      "span",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm.renderComponent &&
                                                    (!item[col.value]
                                                      .offer_item_status_id ||
                                                      item[col.value]
                                                        .offer_item_status_id ==
                                                        3)
                                                      ? _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color: _vm.getColor(
                                                                item[col.value]
                                                              ),
                                                              small: ""
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.selectItem(
                                                                  col.value,
                                                                  item[
                                                                    col.value
                                                                  ].id
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.selected.includes(
                                                                    item[
                                                                      col.value
                                                                    ].id
                                                                  )
                                                                    ? "mdi-checkbox-marked-circle"
                                                                    : "mdi-circle-outline"
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      : _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              small: "",
                                                              color: "primary"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-lock-check"
                                                            )
                                                          ]
                                                        ),
                                                    !item[col.value]
                                                      .offer_item_status_id ||
                                                    item[col.value]
                                                      .offer_item_status_id == 3
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                _vm
                                                                  .totalSelectedByCol[
                                                                  col.value
                                                                ] > 1,
                                                              icon: "",
                                                              "x-small": ""
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                $event.stopPropagation()
                                                                return _vm.editItem(
                                                                  item[
                                                                    col.value
                                                                  ],
                                                                  null
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "primary"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-pencil-outline"
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    },
                                    [
                                      _c("span", [
                                        _c("strong", { staticClass: "ml-1" }, [
                                          _vm._v(
                                            _vm._s(item[col.value].status_name)
                                          )
                                        ]),
                                        _c("br"),
                                        _vm._v(
                                          "(" +
                                            _vm._s(item[col.value].students) +
                                            ") "
                                        ),
                                        item[col.value].join_category_name
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  item[col.value]
                                                    .join_category_name
                                                )
                                              )
                                            ])
                                          : _vm._e(),
                                        item[col.value].modality_name
                                          ? _c("span", [
                                              _c("br"),
                                              _vm._v(
                                                _vm._s(
                                                  item[col.value].modality_name
                                                )
                                              )
                                            ])
                                          : _vm._e(),
                                        item[col.value].day_name
                                          ? _c("span", [
                                              _c("br"),
                                              _vm._v(
                                                _vm._s(item[col.value].day_name)
                                              )
                                            ])
                                          : _vm._e(),
                                        item[col.value].work_time_offer
                                          ? _c("span", [
                                              _c("br"),
                                              _vm._v(
                                                _vm._s(
                                                  item[col.value]
                                                    .work_time_offer
                                                )
                                              )
                                            ])
                                          : _vm._e()
                                      ])
                                    ]
                                  )
                            ],
                            1
                          )
                        : _c("span", { staticClass: "text-center" }, [
                            _vm._v(" --- ")
                          ])
                    ])
                  ]
                }
              }
            })
          ],
          null,
          true
        )
      }),
      _c("sys-register-offer-item", {
        attrs: {
          model: _vm.model,
          models: _vm.models,
          "register-type": "offer",
          show: _vm.showForm,
          title: "Edição de Oferta",
          "status-default": _vm.statusDefault,
          config: _vm.config
        },
        on: {
          onCompleted: _vm.onCompletedRegister,
          "update:show": function($event) {
            _vm.showForm = $event
          }
        }
      }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "290" },
          model: {
            value: _vm.dialogConsistency,
            callback: function($$v) {
              _vm.dialogConsistency = $$v
            },
            expression: "dialogConsistency"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "text-h5" }, [
                _vm._v(" Inconsistências ")
              ]),
              _c("v-card-text", [
                _c(
                  "ul",
                  _vm._l(_vm.dataInconsistencies, function(item, index) {
                    return _c("li", { key: index }, [
                      _vm._v(" " + _vm._s(item) + " ")
                    ])
                  }),
                  0
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.dialogConsistency = false
                        }
                      }
                    },
                    [_vm._v(" Fechar ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
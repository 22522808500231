var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticStyle: { border: "0.5px solid #42A5F5", "border-radius": "10px" },
        attrs: {
          headers: _vm.headers,
          items: _vm.data.data,
          search: _vm.search,
          loading: _vm.loading,
          "fixed-header": "",
          height: "330"
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function() {
              return [
                _c(
                  "v-row",
                  { staticClass: "text-center" },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "text-center",
                        attrs: { cols: "12", sm: "12" }
                      },
                      [_vm._v(" " + _vm._s(_vm.data.title) + " ")]
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "2" } },
                      [
                        _vm.data.title && _vm.filter
                          ? _c("emc-button-icon", {
                              staticClass: "ml-2",
                              attrs: {
                                loading: _vm.loading,
                                icon: "mdi-refresh",
                                text: "Atualizar",
                                color: "light-green"
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  return _vm.loadData()
                                }
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "10" } },
                      [
                        _c("v-text-field", {
                          staticClass: "ml-1 mr-1 mt-0",
                          attrs: {
                            label: "Digite aqui o nome da UC",
                            "prepend-inner-icon": "search"
                          },
                          model: {
                            value: _vm.search,
                            callback: function($$v) {
                              _vm.search = $$v
                            },
                            expression: "search"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "item.discipline_name",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(" " + _vm._s(item.discipline_name) + " "),
                _c("br"),
                _c("small", [
                  _vm._v(
                    _vm._s(item.discipline.code) +
                      " - " +
                      _vm._s(item.discipline.work_load)
                  )
                ])
              ]
            }
          }
        ]),
        model: {
          value: _vm.selected,
          callback: function($$v) {
            _vm.selected = $$v
          },
          expression: "selected"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
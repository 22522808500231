var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    {
      attrs: { icon: _vm.$route.meta.icon, title: _vm.$route.meta.title },
      scopedSlots: _vm._u([
        {
          key: "tools",
          fn: function() {
            return [
              _vm.filter && _vm.filter.notifications
                ? _c(
                    "v-badge",
                    {
                      attrs: {
                        content: _vm.totalInconsistences,
                        value: _vm.totalInconsistences,
                        color: "red",
                        overlap: "",
                        bordered: ""
                      }
                    },
                    [
                      _vm.renderComponentFilter
                        ? _c("emc-button-icon", {
                            attrs: {
                              disabled: !_vm.loadingTableGroup,
                              loading:
                                _vm.loadingFilterGroup &&
                                _vm.selectedFilterGroup == "inconsistency",
                              icon: "mdi-alert-outline",
                              text: "Inconsistências",
                              color: _vm.getColorFilterTable("inconsistency")
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.loadFilterTable("inconsistency")
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.renderComponentFilter
                ? _c("emc-button-icon", {
                    attrs: {
                      disabled: !_vm.loadingTableGroup,
                      loading:
                        _vm.loadingFilterGroup &&
                        _vm.selectedFilterGroup == "merge",
                      icon: "mdi-flag-variant",
                      text: "Merge",
                      color: _vm.getColorFilterTable("merge")
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.loadFilterTable("merge")
                      }
                    }
                  })
                : _vm._e(),
              _vm.renderComponentFilter
                ? _c("emc-button-icon", {
                    attrs: {
                      disabled: !_vm.loadingTableGroup,
                      loading:
                        _vm.loadingFilterGroup &&
                        _vm.selectedFilterGroup == "update",
                      icon: "mdi-information-outline",
                      text: "Atualizações (Últimas 24 horas)",
                      color: _vm.getColorFilterTable("update")
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.loadFilterTable("update")
                      }
                    }
                  })
                : _vm._e(),
              _c("v-divider", { attrs: { vertical: "", inset: "" } }),
              _vm.renderComponentFilter
                ? _c("emc-button-icon", {
                    attrs: {
                      disabled: !_vm.loadingTableGroup,
                      loading:
                        _vm.loadingFilterGroup &&
                        _vm.selectedFilterGroup == "classroom",
                      icon: "mdi-office-building",
                      text: "Presencial",
                      color: _vm.getColorFilterTable("classroom")
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.loadFilterTable("classroom")
                      }
                    }
                  })
                : _vm._e(),
              _vm.renderComponentFilter
                ? _c("emc-button-icon", {
                    attrs: {
                      disabled: !_vm.loadingTableGroup,
                      loading:
                        _vm.loadingFilterGroup &&
                        _vm.selectedFilterGroup == "digital",
                      icon: "mdi-desktop-classic",
                      text: "Digital",
                      color: _vm.getColorFilterTable("digital")
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.loadFilterTable("digital")
                      }
                    }
                  })
                : _vm._e(),
              _vm.renderComponentFilter
                ? _c("emc-button-icon", {
                    attrs: {
                      disabled: !_vm.loadingTableGroup,
                      loading:
                        _vm.loadingFilterGroup &&
                        _vm.selectedFilterGroup == "hybrid",
                      icon: "mdi-gesture-double-tap",
                      text: "Híbrido",
                      color: _vm.getColorFilterTable("hybrid")
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.loadFilterTable("hybrid")
                      }
                    }
                  })
                : _vm._e(),
              _c("v-divider", { attrs: { vertical: "", inset: "" } }),
              _vm.renderComponentFilter
                ? _c("emc-button-icon", {
                    attrs: {
                      disabled: !_vm.loadingTableGroup,
                      loading:
                        _vm.loadingFilterGroup &&
                        _vm.selectedFilterGroup == "freshman",
                      icon: "mdi-star-box-multiple-outline",
                      text: "Calouros",
                      color: _vm.getColorFilterTable("freshman")
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.loadFilterTable("freshman")
                      }
                    }
                  })
                : _vm._e(),
              _vm.renderComponentFilter
                ? _c("emc-button-icon", {
                    attrs: {
                      disabled: !_vm.loadingTableGroup,
                      loading:
                        _vm.loadingFilterGroup &&
                        _vm.selectedFilterGroup == "integralized",
                      icon: "mdi-school-outline",
                      text: "Formandos",
                      color: _vm.getColorFilterTable("integralized")
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.loadFilterTable("integralized")
                      }
                    }
                  })
                : _vm._e(),
              _c("v-divider", { attrs: { vertical: "", inset: "" } }),
              _vm.renderComponentFilter
                ? _c("emc-button-icon", {
                    attrs: {
                      disabled: !_vm.loadingTableGroup,
                      loading:
                        _vm.loadingFilterGroup &&
                        _vm.selectedFilterGroup == "algorithm",
                      icon: "mdi-codepen",
                      text: "Algoritmo",
                      color: _vm.getColorFilterTable("algorithm")
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.loadFilterTable("algorithm")
                      }
                    }
                  })
                : _vm._e(),
              _c("v-spacer"),
              _vm.showTools
                ? _c("sys-grid-offer-pop-over", {
                    staticClass: "mr-2",
                    attrs: { model: _vm.config }
                  })
                : _vm._e(),
              _c("emc-button-icon", {
                attrs: {
                  icon: _vm.tableExpanded
                    ? "mdi-arrow-collapse-horizontal"
                    : "mdi-arrow-expand-horizontal",
                  text: _vm.tableExpanded
                    ? "Reduzir Visualização"
                    : "Ampliar Visualização",
                  color: "light-blue"
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    _vm.tableExpanded = !_vm.tableExpanded
                  }
                }
              }),
              _c("emc-table-icon", {
                attrs: {
                  "tool-tip": _vm.expanded
                    ? "Recolher Tabela"
                    : "Expandir Tabela",
                  color: "primary",
                  icon: !_vm.expanded
                    ? "mdi-arrow-expand-all"
                    : "mdi-arrow-collapse-all"
                },
                on: {
                  click: function($event) {
                    _vm.expanded = !_vm.expanded
                  }
                }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("emc-loader-progress", { attrs: { show: _vm.loadingProgress } }),
      _c(
        "v-container",
        { staticClass: "pa-0 ma-0", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.expanded,
                  expression: "!expanded"
                }
              ]
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "2" } },
                [
                  _c("emc-button-icon", {
                    attrs: {
                      icon: "mdi-database-sync",
                      text: "Carregar Dados",
                      loading: _vm.loadingData,
                      color: "light-blue"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.loadData()
                      }
                    }
                  }),
                  _c("emc-button-icon", {
                    attrs: {
                      "icon-small": "",
                      icon: "mdi-filter-remove-outline",
                      text: "Limpar Filtro",
                      disabled: _vm.disabledButtons,
                      color: "red lighten-3"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.clearFilter()
                      }
                    }
                  }),
                  _c(
                    "emc-util-scroll",
                    { attrs: { height: "60vh" } },
                    [
                      _c("sys-select-brand", {
                        staticClass: "mb-2 mt-1",
                        attrs: {
                          model: _vm.filter.brands,
                          error: _vm.errors.length > 0,
                          errors: _vm.errors.brands,
                          label: "Marca*",
                          dense: "",
                          clearable: "",
                          outlined: "",
                          "auto-complete": "",
                          multiple: "",
                          hideDetails: "auto"
                        },
                        on: {
                          "update:model": function($event) {
                            return _vm.$set(_vm.filter, "brands", $event)
                          },
                          input: function($event) {
                            return _vm.loadDataSelects($event)
                          }
                        }
                      }),
                      _c("sys-select-occupation-area", {
                        staticClass: "mb-2",
                        attrs: {
                          model: _vm.filter.occupationAreas,
                          error: _vm.errors.length > 0,
                          errors: _vm.errors.occupationAreas,
                          label: "Área*",
                          outlined: "",
                          clearable: "",
                          dense: "",
                          "auto-complete": "",
                          multiple: "",
                          hideDetails: "auto"
                        },
                        on: {
                          "update:model": function($event) {
                            return _vm.$set(
                              _vm.filter,
                              "occupationAreas",
                              $event
                            )
                          },
                          input: function($event) {
                            return _vm.loadDataSelects($event)
                          }
                        }
                      }),
                      _c("sys-select-shift", {
                        staticClass: "mb-2",
                        attrs: {
                          model: _vm.filter.shifts,
                          error: _vm.errors.length > 0,
                          errors: _vm.errors.shifts,
                          clearable: "",
                          label: "Turno*",
                          multiple: "",
                          dense: "",
                          outlined: "",
                          hideDetails: "auto"
                        },
                        on: {
                          "update:model": function($event) {
                            return _vm.$set(_vm.filter, "shifts", $event)
                          },
                          input: function($event) {
                            return _vm.loadDataSelects($event)
                          }
                        }
                      }),
                      _c("sys-select-offer-item-category", {
                        ref: "categories",
                        staticClass: "mb-2",
                        attrs: {
                          model: _vm.filter.categories,
                          error: _vm.errors.length > 0,
                          errors: _vm.errors.categories,
                          label: "Modalidade do Curso",
                          dense: "",
                          outlined: "",
                          clearable: "",
                          multiple: "",
                          hideDetails: "auto",
                          disabled: _vm.disabledButtons
                        },
                        on: {
                          "update:model": function($event) {
                            return _vm.$set(_vm.filter, "categories", $event)
                          },
                          input: function($event) {
                            return _vm.loadDataSelects($event, "categories")
                          }
                        }
                      }),
                      _c("sys-select-offer-item-campus", {
                        ref: "campuses",
                        staticClass: "mb-2",
                        attrs: {
                          model: _vm.filter.campuses,
                          error: _vm.errors.length > 0,
                          errors: _vm.errors.campuses,
                          label: "Campus Gerencial",
                          dense: "",
                          outlined: "",
                          clearable: "",
                          "auto-complete": "",
                          multiple: "",
                          hideDetails: "auto",
                          disabled: _vm.disabledButtons
                        },
                        on: {
                          "update:model": function($event) {
                            return _vm.$set(_vm.filter, "campuses", $event)
                          },
                          input: function($event) {
                            return _vm.loadDataSelects($event, "campuses")
                          }
                        }
                      }),
                      _c("sys-select-offer-item-course", {
                        ref: "courses",
                        staticClass: "mb-2",
                        attrs: {
                          model: _vm.filter.courses,
                          error: _vm.errors.length > 0,
                          errors: _vm.errors.courses,
                          label: "Curso",
                          dense: "",
                          outlined: "",
                          clearable: "",
                          "auto-complete": "",
                          multiple: "",
                          hideDetails: "auto",
                          disabled: _vm.disabledButtons
                        },
                        on: {
                          "update:model": function($event) {
                            return _vm.$set(_vm.filter, "courses", $event)
                          },
                          input: function($event) {
                            return _vm.loadDataSelects($event, "courses")
                          }
                        }
                      }),
                      _c("sys-select-offer-item-qualifier", {
                        ref: "qualifiers",
                        staticClass: "mb-2",
                        attrs: {
                          model: _vm.filter.qualifiers,
                          error: _vm.errors.length > 0,
                          errors: _vm.errors.qualifiers,
                          label: "Qualificador",
                          dense: "",
                          outlined: "",
                          clearable: "",
                          "auto-complete": "",
                          multiple: "",
                          hideDetails: "auto",
                          disabled: _vm.disabledButtons
                        },
                        on: {
                          "update:model": function($event) {
                            return _vm.$set(_vm.filter, "qualifiers", $event)
                          },
                          input: function($event) {
                            return _vm.loadDataSelects($event, "qualifiers")
                          }
                        }
                      }),
                      _c("sys-select-offer-item-base-group", {
                        ref: "groups",
                        staticClass: "mb-2",
                        attrs: {
                          model: _vm.filter.baseGroups,
                          error: _vm.errors.length > 0,
                          errors: _vm.errors.baseGroups,
                          label: "Turma Composição",
                          dense: "",
                          outlined: "",
                          clearable: "",
                          "auto-complete": "",
                          multiple: "",
                          "item-text": "full_name",
                          hideDetails: "auto",
                          disabled: _vm.disabledButtons
                        },
                        on: {
                          "update:model": function($event) {
                            return _vm.$set(_vm.filter, "baseGroups", $event)
                          },
                          input: function($event) {
                            return _vm.loadDataSelects($event, "groups")
                          }
                        }
                      }),
                      _c("sys-select-offer-item-discipline", {
                        ref: "disciplines",
                        staticClass: "mb-2",
                        attrs: {
                          model: _vm.filter.disciplines,
                          error: _vm.errors.length > 0,
                          errors: _vm.errors.disciplines,
                          label: "Unidade Curricular",
                          "item-text": "full_name",
                          dense: "",
                          outlined: "",
                          clearable: "",
                          "auto-complete": "",
                          multiple: "",
                          hideDetails: "auto",
                          disabled: _vm.disabledButtons
                        },
                        on: {
                          "update:model": function($event) {
                            return _vm.$set(_vm.filter, "disciplines", $event)
                          },
                          input: function($event) {
                            return _vm.loadDataSelects($event, "disciplines")
                          }
                        }
                      }),
                      _c("sys-select-offer-item-work-time", {
                        ref: "workTimes",
                        staticClass: "mb-2",
                        attrs: {
                          model: _vm.filter.workTimes,
                          error: _vm.errors.length > 0,
                          errors: _vm.errors.workTimes,
                          label: "Semestre de Ingresso",
                          dense: "",
                          outlined: "",
                          "auto-complete": "",
                          multiple: "",
                          clearable: "",
                          hideDetails: "auto",
                          disabled: _vm.disabledButtons
                        },
                        on: {
                          "update:model": function($event) {
                            return _vm.$set(_vm.filter, "workTimes", $event)
                          },
                          input: function($event) {
                            return _vm.loadDataSelects($event, "workTimes")
                          }
                        }
                      }),
                      _c("sys-select-offer-item-base-group-status-filter", {
                        ref: "baseGroupStatuses",
                        staticClass: "mb-2",
                        attrs: {
                          model: _vm.filter.baseGroupStatuses,
                          label: "Status da Composição",
                          error:
                            _vm.errors.brand_id_destiny &&
                            _vm.errors.length > 0,
                          errors: _vm.errors.baseGroupStatuses,
                          dense: "",
                          outlined: "",
                          multiple: "",
                          clearable: "",
                          hideDetails: "auto",
                          disabled: _vm.disabledButtons
                        },
                        on: {
                          "update:model": function($event) {
                            return _vm.$set(
                              _vm.filter,
                              "baseGroupStatuses",
                              $event
                            )
                          }
                        }
                      }),
                      _c("sys-select-offer-item-day-filter", {
                        ref: "offerItemDays",
                        staticClass: "mb-2",
                        attrs: {
                          model: _vm.filter.offerItemDays,
                          error:
                            _vm.errors.offerItemDays && _vm.errors.length > 0,
                          errors: _vm.errors.offerItemDays,
                          label: "Dia da Semana",
                          dense: "",
                          multiple: "",
                          outlined: "",
                          clearable: "",
                          hideDetails: "auto",
                          disabled: _vm.disabledButtons
                        },
                        on: {
                          "update:model": function($event) {
                            return _vm.$set(_vm.filter, "offerItemDays", $event)
                          }
                        }
                      }),
                      _c("sys-select-offer-item-status-filter", {
                        ref: "offerStatuses",
                        staticClass: "mb-2",
                        attrs: {
                          model: _vm.filter.offerStatuses,
                          label: "Status da Oferta",
                          multiple: "",
                          dense: "",
                          includeNull: "",
                          "name-null": "A Cursar",
                          clearable: "",
                          outlined: "",
                          hideDetails: "auto",
                          disabled: _vm.disabledButtons
                        },
                        on: {
                          "update:model": function($event) {
                            return _vm.$set(_vm.filter, "offerStatuses", $event)
                          }
                        }
                      }),
                      _vm._v(" Ordenação Quadro de Oferta "),
                      _c(
                        "v-radio-group",
                        {
                          staticClass: "ml-2",
                          attrs: { disabled: _vm.disabledButtons, row: "" },
                          on: {
                            change: function($event) {
                              return _vm.$refs.tableGroups.loadData()
                            }
                          },
                          model: {
                            value: _vm.filter.orderTableGroups,
                            callback: function($$v) {
                              _vm.$set(_vm.filter, "orderTableGroups", $$v)
                            },
                            expression: "filter.orderTableGroups"
                          }
                        },
                        [
                          _c("v-radio", {
                            attrs: { label: "UC", value: "discipline" }
                          }),
                          _c("v-radio", {
                            attrs: { label: "A Cursar", value: "students" }
                          })
                        ],
                        1
                      ),
                      _vm._v(" Agrupamento de UC's "),
                      _c(
                        "v-radio-group",
                        {
                          staticClass: "ml-2",
                          attrs: { disabled: _vm.disabledButtons, row: "" },
                          on: {
                            change: function($event) {
                              return _vm.$refs.tableGroups.loadData()
                            }
                          },
                          model: {
                            value: _vm.filter.keyDiscipline,
                            callback: function($$v) {
                              _vm.$set(_vm.filter, "keyDiscipline", $$v)
                            },
                            expression: "filter.keyDiscipline"
                          }
                        },
                        [
                          _c("v-radio", {
                            attrs: { label: "Nome", value: "name" }
                          }),
                          _c("v-radio", {
                            attrs: { label: "Nome + Código", value: "id" }
                          })
                        ],
                        1
                      ),
                      _c("s-switch", {
                        staticClass: "ml-3",
                        attrs: { inset: "", label: "Notificações" },
                        model: {
                          value: _vm.filter.notifications,
                          callback: function($$v) {
                            _vm.$set(_vm.filter, "notifications", $$v)
                          },
                          expression: "filter.notifications"
                        }
                      })
                    ],
                    1
                  ),
                  _c("small", [_vm._v("*Filtros Principais")])
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "2" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "12" } },
                        [
                          _c("chart-evolution", {
                            ref: "chartEvolution",
                            attrs: { filter: _vm.filter },
                            on: {
                              loaded: function($event) {
                                return _vm.loaded()
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "12" } },
                        [
                          _c("chart-hybridity", {
                            ref: "chartHybridity",
                            attrs: { filter: _vm.filter },
                            on: {
                              loaded: function($event) {
                                return _vm.loaded()
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "8" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("TableTotalDays", {
                            ref: "tableTotalDays",
                            attrs: { filter: _vm.filter },
                            on: {
                              loaded: function($event) {
                                return _vm.loaded()
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c("TableTotalStudents", {
                            ref: "tableTotalStudents",
                            attrs: { filter: _vm.filter },
                            on: {
                              loaded: function($event) {
                                return _vm.loaded()
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-0", attrs: { fluid: "" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "mt-0",
                  attrs: { fluid: "", cols: "12", sm: "12" }
                },
                [
                  _c("TableGroups", {
                    ref: "tableGroups",
                    attrs: {
                      config: _vm.config,
                      filter: _vm.filter,
                      expanded: _vm.tableExpanded
                    },
                    on: {
                      loaded: function($event) {
                        return _vm.loadedTableGroups(true, $event)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }